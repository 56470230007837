import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: "",
  isLogin: false,
  user: null,
  menu: null,
};

export const authSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    authReducer: (state) => {
      // state.token = action.payload.token;
      state.isLogin = true;

      // state.menu = action.payload.menu;
    },
    isSignout: (state) => {
      state.token = "";
      state.isLogin = false;
      state.user = null;
      state.menu = null;
    },
  },
});

export const { authReducer, isSignout } = authSlice.actions;
export default authSlice.reducer;
