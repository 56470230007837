import React, { Suspense, lazy } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PageNotFound from "./pages/404";
import { useState } from "react";
const MyClinicPatientInvoice = lazy(() =>
  import("./pages/MyClinicPatientInvoice")
);
function App() {
  // const [isAnyModalOpen, setAnyModalOpen] = useState(false);
  return (
    <>
      <ToastContainer
        style={{ fontSize: "14px", zIndex: "1000" }}
        autoClose={2000}
        hideProgressBar={true}
      />
      <Suspense fallback={<></>}>
        <Router>
          <Routes>
            {/* without login */}
            <Route path="/404" exact element={<PageNotFound />} />
            <Route path="*" exact element={<MyClinicPatientInvoice />} />
          </Routes>
        </Router>
      </Suspense>
    </>
  );
}

export default App;
