import React from "react";
import { useNavigate } from "react-router-dom";
import classes from "./ErrorPage.module.css";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div className={`${classes["main"]} row mx-auto`}>
      <div
        className={`${classes["div-text"]}
          col-lg-6 col-sm-12`}
      >
        <h1 className="mb-4">Accept our apologies!</h1>
        <h5 className="mb-4">
          The page you are looking for doesn't exist. You may mistyped the
          address or the page may have moved
        </h5>
        <button
          onClick={() => navigate(-1)}
          className={`${classes["btn"]} ${classes["green"]}`}
        >
          Go Back
        </button>
      </div>
      <div
        className={`${classes["div-404"]}
          col-lg-6 col-sm-12 d-flex justify-content-center`}
      >
        <h1>404</h1>
      </div>
    </div>
  );
};

export default ErrorPage;
